import classnames from "classnames";
import { withPrefix } from "gatsby";
import _, {get} from "lodash";
import React from "react";
import { SocialIcon } from "react-social-icons";
import * as styles from "./index.module.scss";
import {getPointsBalanceFromUser} from "../../logic/user/getters/getPointsBalanceFromUser";
import {getUserFirstName} from "../../logic/user/getters/getUserFirstName";
import LoyaltyTracker from "./LoyaltyTracker";
import { getStoreCreditBalanceFromUser } from "../../logic/user/getters/getStoreCreditBalanceFromUser";

export default ({
  appStyles = {},
  T,
  desktopExtraTitle,
  kioskTitle,
  className,
  user,
  pointBankCoupons
}) => {
  const {
    HomeFirstTitle,
    HomeMobileFirstTitle,
    HomeSecondTitle,
    HomeExtraTitlesDesktop,
    HomeMainTitleDesktop,
    HomeThirdTitle,
    DesktopTitleLogo,
    hompageTitleCentered: center,
  } = appStyles;

  const logoImage = _.get(DesktopTitleLogo, "backgroundImage");
  const { backgroundImage, ...logoImageProps } = DesktopTitleLogo || {};
  const logoBackground = logoImage && `url(${withPrefix(logoImage)})`;

  const firstName = getUserFirstName(user);
  const title = user ? T("Hi") + ` ${firstName}` : T("Welcome to");
  const rewardPointsBalance = getPointsBalanceFromUser(user);
  const visitCount = get(user, "loyaltyProfile.data.visitCount", "-");
  const storeCreditBalance = getStoreCreditBalanceFromUser(user);

  return (
    <div className={'home-points'}>
      <div
        className={classnames(
          className,
          styles.HomePoints,
          center && styles.Center,
          styles.HideOnDesktop,
        )}
      >
        <span
          style={{
            ...(HomeMobileFirstTitle || HomeFirstTitle),
            // marginLeft: 10,
          }}
        >
          {title}
        </span>
        <div className={styles.LoyaltyContainer}>
          {storeCreditBalance > 0 && (
            <>
              <div className={styles.visitAndPoints}>
                <div className={styles.number}>
                  ${storeCreditBalance}
                </div>
                <div className={styles.label}>
                  {T("Credit")}
                </div>
              </div>
              <div className={styles.divider}>
                <div className={styles.line} />
              </div>
            </>
          )}
          
          <div className={styles.visitAndPoints}>
            <div className={styles.number}>
              {rewardPointsBalance}
            </div>
            <div className={styles.label}>
              {T("Points")}
            </div>
          </div>
        </div>
        <LoyaltyTracker user={user} pointBankCoupons={pointBankCoupons}/>
      </div>
      <div
        className={classnames(
          styles.HomeTitleDesktop,
          center && styles.Center,
          styles.HideOnMobile,
        )}
      >
        {DesktopTitleLogo && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                ...logoImageProps,
                backgroundImage: logoBackground,
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                height: `${_.get(logoImageProps, "height")}px`,
                width: `${_.get(logoImageProps, "width")}px`,
              }}
            />
          </div>
        )}
        {kioskTitle ? (
          <div
            className={styles.TitlePadding}
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <span style={{ ...HomeSecondTitle, marginBottom: 60 }}>
              {T("{{business_name_desktop}}")}
            </span>
            <span style={HomeMainTitleDesktop}>{kioskTitle}</span>
          </div>
        ) : appStyles.desktopTitleOnSeparateLine ? (
          <div className={classnames(styles.HomeTitleDesktop)}>
            <span style={HomeFirstTitle}>{T("{{desktop_title_prefix}}")}</span>
            <span style={HomeMainTitleDesktop}>
              {T("{{business_name_desktop}}")}
            </span>
            <span style={HomeThirdTitle}>{T("{{desktop_title_suffix}}")}</span>
          </div>
        ) : (
          <span
            style={HomeMainTitleDesktop}
            className={styles.TitlePadding}
          >{`${T("{{desktop_title_prefix}}")} ${T(
            "{{business_name_desktop}}",
          )}${
            _.startsWith(T("{{desktop_title_suffix}}"), "'")
              ? T("{{desktop_title_suffix}}")
              : ` ${T("{{desktop_title_suffix}}")}`
          }`}</span>
        )}
        <span
          style={{
            color: appStyles.accentColor,
            paddingLeft: 16,
            paddingRight: 16,
            ...HomeExtraTitlesDesktop,
          }}
        >
          {desktopExtraTitle}
        </span>
        {appStyles.socialLinks && (
          <div
            style={{
              paddingLeft: appStyles.alignSocialLinksToLeft ? 8 : 0,
              display: "flex",
              justifyContent: appStyles.alignSocialLinksToLeft
                ? "flex-start"
                : "center",
            }}
          >
            {_.map(
              _.filter(_.values(appStyles.socialLinks), _.negate(_.isEmpty)),
              (url, indx) => (
                <SocialIcon
                  key={url + indx}
                  target="_blank"
                  url={url}
                  style={{ margin: 4, ...appStyles.SocialLink }}
                  fgColor="#fff"
                  bgColor={appStyles.actionColor}
                />
              ),
            )}
          </div>
        )}
      </div>
    </div>
  );
};
