import {get, round} from "lodash";

export function getStoreCreditBalanceFromUser(user) {
    const openChargeCardInstance = get(
      user,
      "loyaltyProfile.data.openChargeCardInstance",
    );
  
    const amountOnCard = openChargeCardInstance
      ? openChargeCardInstance.loadedAmount - openChargeCardInstance.usedAmount
      : 0;
  
    return round(amountOnCard, 2);
  }