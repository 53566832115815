import React from "react";
import AppContainer from "../AppContainer";
import {HOMEPAGE_TEMPLATE_KEY} from "../../utils/constants";
import DefaultTemplate from "./DefaultTemplate";
import {getTemplateFromAppStyles} from "../../logic/templates/getTemplateFromAppStyles";

export default function IndexView({
  startNewOrder,
  order,
  T,
  appStyles,
  promotions,
  pageContext,
  user,
  location,
  openAuthView,
  app,
  openNewOrPrevOrderModal,
  closeNewOrPrevOrderModal
}) {

  function renderContent() {
    const template = getTemplateFromAppStyles(appStyles);
    switch (template) {
      case HOMEPAGE_TEMPLATE_KEY.minimalist:
      case HOMEPAGE_TEMPLATE_KEY.loyalty:
      case HOMEPAGE_TEMPLATE_KEY.newsfeed:
      case HOMEPAGE_TEMPLATE_KEY.default:
      default:
        return <DefaultTemplate
          startNewOrder={startNewOrder}
          order={order}
          T={T}
          appStyles={appStyles}
          promotions={promotions}
          pageContext={pageContext}
          user={user}
          location={location}
          openAuthView={openAuthView}
          openNewOrPrevOrderModal={openNewOrPrevOrderModal}
          closeNewOrPrevOrderModal={closeNewOrPrevOrderModal}
          app={app}
        />
    }
  }

  return (
    <AppContainer.ResponsiveWrapper fullscreen>
      {renderContent()}
    </AppContainer.ResponsiveWrapper>
  );
}
